import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import ContactSection from '../components/ContactSection'
import AngledDivider from '../components/AngledDivider'
import BigHeading from '../components/BigHeading'
import Paragraph from '../components/Paragraph'
import WordArt from '../components/WordArt'
import ContentBlock from '../components/ContentBlock'
import HeroBlock from '../components/HeroBlock'
import FlexBox from '../components/FlexBox'
import Person from '../components/Person'

const people = [
  {
    name: 'Frank Rocks',
    title: 'Account Executive',
    image: 'about-frank.jpg'
  },
  { name: 'Jack Poole', title: 'Creative Manager', image: 'about-jackp.jpg' },
  {
    name: 'Cameron McKinley',
    title: 'Marketing Analyst',
    image: 'about-camm.jpg'
  },
  {
    name: 'Jack Habib',
    title: 'Production Assistant',
    image: 'about-jackh.jpg'
  },
  {
    name: 'Tim Glorioso',
    title: 'Developer / Designer',
    image: 'about-tim.jpg'
  }
]

const About = ({ data }) => (
  <React.Fragment>
    <SEO title="About" />
    <HeroBlock backgroundColor="#0a0a0a">
      <HeroHeading />
    </HeroBlock>
    <AngledDivider color="#1b1b1b" position="top" />
    <ContentBlock backgroundColor="#1b1b1b">
      <BigHeading>Our Purpose</BigHeading>
      <Paragraph>
        Helping brands communicate what they do, how they do it and why they do
        it is our forte. Strategic communication has been the foundation of our
        growth. We strongly believe in the emotional reaction consumers
        experience when introduced to a new brand—and there’s no better feeling
        than when you effectively convey that message for a client.
      </Paragraph>
    </ContentBlock>
    <ContentBlock backgroundColor="#1b1b1b">
      <BigHeading>Our Story</BigHeading>
      <Paragraph>
        In 2016 Orange Astronaut was founded by three hard-working entrepreneurs
        with a love for all things media. At the beginning, they worked in an
        old building on a forgotten corner with no air conditioning. Around the
        same time, on the same corner, a small hotel opened for business—so the
        Astronauts did what any good friend would do and they bought the hotel
        owner a drink. The hotel became OA’s first client and the agency helped
        turn the faceless establishment into a booming brand name. As more and
        more traffic made its way to the hotel, things on the street started to
        change, and the corner became far from forgotten. Mission accomplished.
      </Paragraph>
    </ContentBlock>
    <ContentBlock backgroundColor="#1b1b1b">
      <BigHeading
        css={`
          margin-bottom: 6vw;
        `}
      >
        The Astronauts
      </BigHeading>
      <FlexBox
        flexDirection="row"
        justifyContent="space-evenly"
        css={`
          flex-wrap: wrap;
        `}
      >
        {people.map((person, index) => (
          <Person
            key={index}
            {...person}
            image={
              data.peopleImages.edges.find(
                edge => edge.node.relativePath === person.image
              ).node.childImageSharp.fixed
            }
          />
        ))}
      </FlexBox>
    </ContentBlock>
    <AngledDivider color="#1b1b1b" position="bottom" />
    <ContactSection />
  </React.Fragment>
)

const HeroHeading = props => (
  <WordArt.Wrapper>
    <WordArt.Line color="#fff" fontSize="1.65em">
      We Are
    </WordArt.Line>
    <WordArt.Line color="#f93616" fontSize="1.55em">
      Orange
    </WordArt.Line>
    <WordArt.Line color="#fff" fontSize="1.05em">
      Astronaut
    </WordArt.Line>
  </WordArt.Wrapper>
)

export const query = graphql`
  query {
    peopleImages: allFile(
      filter: {
        relativePath: {
          in: [
            "zach.png"
            "about-frank.jpg"
            "brennan.jpg"
            "about-jackp.jpg"
            "about-jackh.jpg"
            "about-camm.jpg"
            "about-camd.jpg"
            "about-tim.jpg"
          ]
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fixed(height: 200, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`

export default About
